import _ from 'lodash';
import { useState, useEffect } from 'react';
import { Breakpoints } from './types';

export default function useIsTablet(breakpointsPx: Breakpoints): boolean {
  function getIsTablet(): boolean {
    return breakpointsPx.md <= window.innerWidth && window.innerWidth < breakpointsPx.lg;
  }

  const [tablet, setTablet] = useState(getIsTablet);

  useEffect(() => {
    function handleResize() {
      setTablet(getIsTablet());
    }
    const throttledHandler = _.throttle(() => handleResize(), 100);
    window.addEventListener('resize', throttledHandler);
    return () => window.removeEventListener('resize', throttledHandler);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return tablet;
}
