import { useCallback, useEffect, useRef } from 'react';

function useIsMounted(): () => boolean {
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return useCallback(() => isMountedRef.current, []);
}

export default useIsMounted;
