import React, { useState, useEffect } from 'react';

export default function useOnScreen(ref: React.RefObject<HTMLDivElement>, rootMargin = '0px') {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  let reference = ref.current;
  useEffect(() => {
    reference = ref.current; //eslint-disable-line
  }, [ref]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );
    if (reference) {
      observer.observe(reference);
    }
    return () => {
      if (reference) {
        observer.unobserve(reference);
      }
    };
  }, []); // eslint-disable-line
  // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
}
