import { useCallback, useEffect } from 'react';

/**
 *
 * @param submit function to be called when Enter is pressed
 * @param block Prevents function being called if Enter pressed
 */
export default function (submit: any, block: boolean) {
  const checkEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 13 && !block) submit();
    },
    [submit, block],
  );

  useEffect(() => {
    window.addEventListener('keydown', checkEnter);
    return () => {
      window.removeEventListener('keydown', checkEnter);
    };
  }, [checkEnter]);
}
