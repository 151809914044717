import { useEffect, useState, useCallback, useRef } from 'react';

export default function useKeyCode(targetKey: number): boolean {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);
  const prevKeyCode = useRef(null as number | null);

  const downHandler = useCallback(
    ({ keyCode }: { keyCode: number }) => {
      if (prevKeyCode.current === targetKey) return;
      if (keyCode === targetKey) {
        setKeyPressed(true);
        prevKeyCode.current = keyCode;
      }
    },
    [targetKey],
  );

  const upHandler = useCallback(
    ({ keyCode }: { keyCode: number }) => {
      if (keyCode === targetKey) {
        setKeyPressed(false);
        prevKeyCode.current = null;
      }
    },
    [targetKey],
  );

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, upHandler]);

  return keyPressed;
}
