import _ from 'lodash';
import { useState, useEffect } from 'react';

// Returns the window.innerheight as Real Viewport Height, so that mobile browsers work consistently
// This is used for adjusting heights to mobile viewpowrts, where the URL bar scrolls out of view and messes up the native css 'vh' unit
export default function useRVH(): number {
  const [rvh, setRvh] = useState((typeof window !== 'undefined' && window.innerHeight * 0.01) || 0);

  useEffect(() => {
    function handleResize() {
      setRvh(window.innerHeight * 0.01);
    }
    const throttledHandler = _.throttle(() => handleResize(), 100);
    window.addEventListener('resize', throttledHandler);
    return () => window.removeEventListener('resize', throttledHandler);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return rvh;
}
