import { useCallback, useEffect, useState } from 'react';
import { Breakpoints } from './types';

export default function useSplitter(
  splitterId: string,
  containerWidth: number,
  breakpointsPx: Breakpoints,
): [number, number, (w: number) => void] {
  const position =
    (typeof window !== 'undefined' && window.localStorage.getItem(splitterId)) || '0';

  const storedPosition = parseInt(position, 10);
  const [leftPaneWidth, setLeftPaneWidth] = useState(storedPosition);
  const [rightPaneWidth, setRightPaneWidth] = useState(containerWidth - storedPosition);

  const setSplitPosition = useCallback(
    (width: number) => {
      if (typeof width !== 'number') return;
      setLeftPaneWidth(width);
      setRightPaneWidth(containerWidth - width);
      window.localStorage.setItem(splitterId, JSON.stringify(width));
    },
    [containerWidth, setLeftPaneWidth, setRightPaneWidth, splitterId],
  );

  useEffect(() => setSplitPosition(storedPosition || breakpointsPx.md), [
    setSplitPosition,
    storedPosition,
  ]);

  return [leftPaneWidth, rightPaneWidth, setSplitPosition];
}
