import { useEffect, useRef } from 'react';

/** Call a function on an interval
 * @param callback the function to call on every interval
 * @param interval amount of miliseconds between every function call
 */
export default function useInterval(callback: any, delay?: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
