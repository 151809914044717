import { mergeWith } from 'lodash';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import useEnterToSubmit from './useEnterToSubmit';

export type DropdownOption<T = string> = { label: string; value: T | ''; disabled?: boolean };
type BaseInput = boolean | string | number | Date | undefined;

type FormValue = BaseInput | Array<BaseInput>;
export type FormValueObject<T> = {
  [key in keyof T]: T[key] extends FormValue ? FormValue : FormValueObject<T[key]>;
};

// TODO:  Add generic to this so that it's not FormValue but the actual type that it is
export type FormStateField<T extends FormValue> = {
  value: T;
  error: string;
  touched: boolean;
};
export type FormState<T> = {
  [field in keyof T]: T[field] extends FormValue ? FormStateField<T[field]> : FormState<T[field]>;
};

export type Errors<T> = { general: string } & {
  [key in keyof T]: T[key] extends FormValue ? string : Errors<T[key]>;
};

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>;
};

/**
 * Form state management hook
 *
 * Fields contain value, error (from validation) and touched
 * @param initialValues form initialization
 * @param handleSubmit gets form values and handles submission
 * @param validate function that validates fields in form. Should return an object of error strings whose keys are those of the initial form state
 * @param blockEnterToSubmit prevent form submission upon pressing enter
 *
 * Rules:
 *  - Form will not submit if there are any errors
 *  - Any untouched fields will become touched if a submission attempt is made
 *  - Form will submit on Enter unless 'blockEnterToSubmit' is passed
 *
 *  - form STATE refers to the form that has been augmented to hold useful things for form UI, such as TOUCHED or ERROR
 *  - form VALUES refers to the key value pairs of the actual form (i.e. what would be submitted in a mutation)
 *
 * @returns {
 *  formState - form state including errors, touched
 *  handleChange - Directly modify the from state with a { key: value } object. Will get parsed into state by useForm
 *  handleBlur - ATM just runs validation
 *  submitForm - submit form with given function that receives form VALUE, not form STATE
 *  triggerValidation - trigger validation logic to populate errors based on the current form state without submitting form
 * }
 *
 */

export default function useForm<ValueStructure>({
  initialValues,
  handleSubmit,
  validate,
  blockEnterToSubmit,
}: {
  initialValues: FormValueObject<ValueStructure>;
  handleSubmit: (values: ValueStructure) => void;
  // TODO:  Make validate asynchronous
  validate: (values: ValueStructure, errors: Errors<ValueStructure>) => Errors<ValueStructure>;
  blockEnterToSubmit?: boolean;
}) {
  //======================================================
  // Error handling
  //======================================================
  const insertIntoBlankErrors = useCallback(<Input>(input: any): any => {
    const key = Object.keys(input)[0] as keyof Input;
    const value = input[key];

    if (isStateFieldType(value)) {
      return { [key]: '' };
    } else {
      const formStateObject = value as FormState<Input>;
      return {
        [key]: Object.keys(formStateObject).reduce(
          (state, subKey) => ({
            ...state,
            ...insertIntoBlankErrors<Input[keyof Input]>({
              [subKey]: formStateObject[subKey as keyof Input],
            }),
          }),
          {} as FormValueObject<Input[keyof Input]>,
        ),
      };
    }
  }, []);

  // INSERT INTO STATE
  // Recursive, can return whole object or just portion/member of sub-objects
  // input is a {key: value} object whose value is either a FormValue or an object with other fields in
  const getStateFromValues = useCallback(
    <Input>(
      input: FormValueObject<Input>,
      override?: Partial<FormStateField<FormValue>>,
    ): FormState<Input> => {
      const { key, value } = validateStoVInput<FormValueObject<Input>>(input);

      // if value of input is value type, convert to form-state type and insert into form state
      if (isValueType(value)) {
        // @ts-ignore
        return { [key]: { value, error: '', touched: false, ...override } };
      } else {
        // If value of input is object, recursively perform insertion until bottom layer is reached
        const objectValue = value as FormValueObject<Input[keyof Input]>;

        // TODO:  This thinks that key is a string, when it is actually a key of input, no idea why :(
        // @ts-ignore
        return {
          [key]: Object.keys(objectValue).reduce(
            (state, subKey) => ({
              ...state,
              ...getStateFromValues<Input[keyof Input]>(
                // @ts-ignore
                {
                  [subKey as keyof Input]: objectValue[subKey as keyof Input[keyof Input]],
                },
                override,
              ),
            }),
            {} as FormState<Input[keyof Input]>,
          ),
        };
      }
    },
    [],
  );

  // INSERT INTO VALUES
  const getValuesFromState = useCallback(
    <Input>(input: FormState<Input>): FormValueObject<Input> => {
      const key = Object.keys(input)[0] as keyof FormState<Input>;
      const value = input[key];

      if (isStateFieldType(value)) {
        const stateValue = value as FormStateField<FormValue>;

        // TODO:  This thinks that key is a string, when it is actually a key of input, no idea why :(
        // @ts-ignore
        return { [key]: stateValue.value };
      } else {
        const formStateObject = value as FormState<Input[keyof Input]>;
        // @ts-ignore
        return {
          [key]: Object.keys(formStateObject).reduce(
            (state, subKey) => ({
              ...state,
              // @ts-ignore
              ...getValuesFromState({
                [subKey]: formStateObject[subKey as keyof FormState<Input[keyof Input]>],
              }),
            }),
            {} as FormValueObject<Input[keyof Input]>,
          ),
        };
      }
    },
    [],
  );

  /**
   * Initialise form state with FormStateField objects for each initial value passed in
   *  - Recursively insert where objects are in the form state
   */
  if (!initialValues) throw new Error('No initial form values passed!');
  let initialState = {} as FormState<ValueStructure>;

  for (const field in initialValues) {
    const value = initialValues[field] as FormValueObject<ValueStructure[keyof ValueStructure]>;
    initialState = {
      ...initialState,
      // TODO:  This thinks that key is a string, when it is actually a key of input, no idea why :(
      ...getStateFromValues<ValueStructure[keyof ValueStructure]>(
        // @ts-ignore
        {
          [field as keyof ValueStructure]: value,
        },
      ),
    };
  }

  const [formState, setFormState] = useState<FormState<ValueStructure>>(initialState);
  const [formHasErrors, setFormHasErrors] = useState(false);
  const [formIsTouched, setFormIsTouched] = useState(false);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);

  /**
   * Create a basic errors object from the shape of the form state
   */
  const createBaseErrors = useCallback((): Errors<ValueStructure> => {
    let values = {} as Errors<ValueStructure>;
    for (const field in formState) {
      values = { ...values, ...insertIntoBlankErrors({ [field]: formState[field] }) };
    }
    return values;
  }, [insertIntoBlankErrors, formState]);

  const [errors, setErrors] = useState<Errors<ValueStructure>>(createBaseErrors());

  /**
   * Parse an object of FormState<ValueStructure> into an object of { key: value } so that it
   * can be used outside of the form
   * @param fields fields to parse
   */
  const parseStateToValues = useCallback((): ValueStructure => {
    let values = {} as ValueStructure;

    for (const field in formState) {
      values = { ...values, ...getValuesFromState({ [field]: formState[field] }) };
    }
    return values;
  }, [getValuesFromState, formState]);

  const insertErrorsIntoStateObject = useCallback(
    <Input>(
      state: FormStateField<FormValue> | FormState<Input>,
      error: any,
      setAllTouched: boolean,
    ): FormStateField<FormValue> | FormState<Input> => {
      const key = Object.keys(error)[0];

      if (isStateFieldType(state)) {
        const stateType = state as FormStateField<FormValue>;
        return {
          value: stateType.value,
          error: error[key],
          touched: setAllTouched || stateType.touched,
        };
      } else {
        const formStateObject = state as FormState<Input>;

        // TODO:  The initializer for this reduce is FormState<Input>. It's not complaining but I'm pretty sure that's wrong (should be member of Input)
        return Object.keys(formStateObject).reduce(
          (subState, subKey) => ({
            ...subState,
            [subKey]: insertErrorsIntoStateObject(
              formStateObject[subKey as keyof Input],
              {
                [subKey]: error[key][subKey],
              },
              setAllTouched,
            ),
          }),
          {} as FormState<Input>,
        );
      }
    },
    [],
  );

  const insertErrorsIntoState = useCallback(
    (errors: Errors<ValueStructure>, setAllTouched: boolean) => {
      let values = {} as FormState<ValueStructure>;

      for (const field in formState) {
        values = {
          ...values,
          [field]: insertErrorsIntoStateObject(
            formState[field],
            {
              [field]: errors[field as keyof ValueStructure],
            },
            setAllTouched,
          ),
        };
      }
      setFormState(values);
    },
    [formState, insertErrorsIntoStateObject],
  );

  const hasErrors = useCallback(<ErrorLayer>(layer: ErrorLayer, errorFound?: boolean): any => {
    if (errorFound) return true;
    for (const field in layer) {
      if (isValueType(layer[field])) {
        if (layer[field]) return true;
      } else {
        return hasErrors(layer[field]);
      }
    }
  }, []);

  /**
   * Recursive function that traverses form state to see if any values have been touched
   */
  const isTouched = useCallback(
    <Input>(stateValue: FormStateField<FormValue> | FormState<Input>): boolean => {
      if (isStateFieldType(stateValue)) {
        if (stateValue.touched) return true;
      } else {
        for (const key in stateValue) {
          if (isTouched(stateValue[key])) return true;
        }
      }
      return false;
    },
    [],
  );

  useEffect(() => {
    if (!formIsTouched) {
      if (isTouched(formState)) {
        setFormIsTouched(true);
      }
    }
  }, [formState, formIsTouched, isTouched]);

  /**
   * Set values of form
   * @param values values within form to be changed
   *
   * *FORMAT VALUES AS YOU WANT THEM TO BE STORED IN FORM STATE*
   */

  // TODO:  This needs some kind of validation to allow the lib to tell you if you are passing the wrong shit to it
  // TODO:  Would be nice to be able to pass a string as a path and this function could build the object from the string
  // TODO:  Updating form state with deep objects?
  const handleChange = useCallback(
    (values: DeepPartial<FormValueObject<ValueStructure>>) => {
      setFormState(prevState => {
        // For all values being changed clear error and set touched to true
        const newVals = getStateFromValues((values as unknown) as FormValueObject<ValueStructure>, {
          touched: true,
        });
        const newState = mergeWith(prevState, newVals, (formValue, newValue) => {
          if (Array.isArray(formValue?.value)) {
            return newValue;
          }
        });
        return { ...newState };
      });
    },
    [getStateFromValues, setFormState],
  );

  /**
   * Handle errors when field blurred
   * TODO:  might be necessary to add something here so that you can render the
   *        error of the specific field being blurred? Would need to pass the
   *        location of the field within state to do that.
   */
  const handleBlur = useCallback(() => {
    // Set errors for specified fields
    const errors = validate(parseStateToValues(), createBaseErrors());
    insertErrorsIntoState(errors, false);
    setFormHasErrors(hasErrors(validate(parseStateToValues(), createBaseErrors())));
  }, [
    validate,
    parseStateToValues,
    createBaseErrors,
    insertErrorsIntoState,
    setFormHasErrors,
    hasErrors,
  ]);

  /**
   * Submit form using provided handleSubmit function after performing validation
   */
  const submitForm = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setSubmissionAttempted(true);
    const errors = validate(parseStateToValues(), createBaseErrors());
    if (hasErrors(errors)) {
      setFormHasErrors(true);
      insertErrorsIntoState(errors, true);
      setErrors(errors);
    } else {
      setFormHasErrors(false);
      handleSubmit(parseStateToValues());
    }
  };

  /**
   * Trigger form validation to populdate erros without submission
   */
  const triggerValidation = (): boolean => {
    const errors = validate(parseStateToValues(), createBaseErrors());
    if (hasErrors(errors)) {
      setFormHasErrors(true);
      insertErrorsIntoState(errors, true);
      setErrors(errors);
      return true;
    } else {
      setFormHasErrors(false);
      return false;
    }
  };

  const resetSubmissionAttempted = useCallback(() => setSubmissionAttempted(false), []);

  useEnterToSubmit(submitForm, Boolean(blockEnterToSubmit || !formIsTouched));

  return {
    formState,
    handleChange,
    handleBlur,
    submitForm,
    formHasErrors,
    errors,
    submissionAttempted,
    resetSubmissionAttempted,
    triggerValidation,
  };
}
/**
 *
 * ==============================================
 * ==============================================
 *               Utility functions
 * ==============================================
 * ==============================================
 */

/**
 * Test if { key: value } (FORM VALUES) type
 * @param value form value to test
 */
function isValueType(toBeDetermined: any): toBeDetermined is FormValue {
  if (
    typeof toBeDetermined === 'string' ||
    typeof toBeDetermined === 'number' ||
    typeof toBeDetermined === 'boolean' ||
    Array.isArray(toBeDetermined) ||
    toBeDetermined instanceof Date ||
    typeof toBeDetermined === 'undefined'
  )
    return true;
  return false;
}

/**
 * Test if { key: FormState<ValueStructure> } (FORM STATE) type
 * @param value state to test
 */
function isStateFieldType(toBeDetermined: any): toBeDetermined is FormStateField<FormValue> {
  return (
    Object.keys(toBeDetermined as FormStateField<FormValue>).includes('value') &&
    Object.keys(toBeDetermined as FormStateField<FormValue>).includes('touched') &&
    Object.keys(toBeDetermined as FormStateField<FormValue>).includes('error')
  );
}

/**
 * Form validation helpers
 * @function isMissing check if field is missing form form (check if required)
 * @function isInvalidPhoneNumber check is phone number is valid
 * @function hasIncorrectLength check string length
 * @function isOutideBoundary check number is within bounds
 */

/**
 *
 * General field validation
 *
 */

/**
 * Check if required field is missing
 * @param value form value
 */
export const isMissing = (value: any) => {
  if (typeof value === 'string') {
    return !Boolean(value);
  }
  if (typeof value === 'number') {
    return !(value || value === 0);
  }
  if (typeof value === 'boolean') return false;
  return !Boolean(value);
};

/**
 * Check string is of the correct length (within min/max bounds)
 * @param value form value
 * @param { max, min } maximum/minimum string length
 */
export const isIncorrectLength = (
  value: string | Array<any>,
  { min, max }: { min?: number; max?: number },
) => {
  if (typeof value !== 'string' && !Array.isArray(value)) {
    console.error('isIncorrectLength: value must be of type string or array, got ', typeof value);
    return true;
  }
  if (!min && !max) {
    console.error('max or min bounds must be passed to hasIncorrectLength!');
    return true;
  }

  if (min && value.length < min) return true;
  if (max && value.length > max) return true;
};

/**
 * Check number is within boundaries
 * @param value form value
 * @param bounds { max, min } boundaries number should be within
 */
export const isOutsideBoundary = (
  value: number | undefined,
  { min, max }: { min?: number; max?: number },
) => {
  if (typeof value !== 'number' && value !== undefined) {
    console.error('isOutsideBoundary: value must be of type number, got ', typeof value);
    return true;
  }
  if (value === undefined) {
    return false;
  }

  if (!min && !max) {
    console.error('isOutsideBoundary: max or min must be passed to isOutideBoundary!');
    return true;
  }

  if (min && value < min) return true;
  if (max && value > max) return true;
};

/**
 *
 * Personalia validation
 *
 */

/**
 * Check if phone number is valid
 * @param value form value
 */
export const isInvalidPhoneNumber = (value: string) => {
  if (typeof value !== 'string') {
    console.warn('isInvalidPhoneNumber: phone number must be passed as string!');
    return true;
  }
  // TODO: use international regex when we add the front-end bits for it
  // return !GENERIC_INTERNATIONAL_PHONE_REGEX.test(value);
  const land = /^(((0)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))$/;
  const mobile = /^(((\\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i;
  return !(land.test(value) || mobile.test(value));
};

export const isInvalidEmail = (value: string) => {
  if (typeof value !== 'string') {
    console.warn('isInvalidEmail: email must be passed as a string!');
    return true;
  }
  if (value === '') return false;

  const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; // eslint-disable-line no-control-regex
  return !emailRegex.test(value);
};

export const isInvalidUrl = (value: string) => {
  if (typeof value !== 'string') {
    console.warn('isInvalidUrl: url must be passed as a string!');
    return true;
  }
  if (value === '') return false;

  try {
    new URL(value);
    return false;
  } catch (error) {
    console.warn(error);
    return true;
  }
};

/**
 * Check if postcode is valid NL postcode
 * @param value postcode to check
 */
export const isInvalidPostcode = (value: string) => {
  if (typeof value !== 'string') {
    console.error('Value must be of type string, got ', typeof value);
    return true;
  }
  const postcode = /^[1-9][0-9]{3}[\s]?[A-Z]{2}$/i;
  return !postcode.test(value);
};

/**
 *
 * ==============================================
 * ==============================================
 *            Formatting/parsing shit
 * ==============================================
 * ==============================================
 */
// export const handleCheckboxes = (state: Array<BaseInput>, e: BaseValue) => {

// }

/**
 *
 * ==============================================
 * ==============================================
 *             Other Useful Shit
 * ==============================================
 * ==============================================
 */

const validateStoVInput = <Input>(input: Partial<Input>) => {
  const keys = Object.keys(input);
  if (keys.length < 1 || keys.length > 1)
    throw new Error('Only insert one field at a time into form state');
  const key = keys[0] as keyof Input;
  const value = input[key];
  return { key, value };
};

export const useFormUtils = {
  isValueType,
  isStateFieldType,
  isMissing,
  isIncorrectLength,
  isOutsideBoundary,
  isInvalidPhoneNumber,
  isInvalidEmail,
  isInvalidUrl,
};
