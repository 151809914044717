import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

type PageTitleContext = [string, Dispatch<SetStateAction<string>> | null];

export const PageTitleContext = React.createContext<PageTitleContext>(['', null]);

export const PageTitleProvider: React.FC = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('');
  const contextValue: PageTitleContext = [pageTitle, setPageTitle];
  return <PageTitleContext.Provider value={contextValue}>{children}</PageTitleContext.Provider>;
};

export default function usePageTitle() {
  const [pageTitle, setPageTitle] = useContext(PageTitleContext);
  return {
    pageTitle,
    setPageTitle: setPageTitle as Dispatch<SetStateAction<string>>, // It will always be set after initialization, no need to check for null
  };
}
