import { useLayoutEffect } from 'react';

export default function useScrollLock(isActive: boolean): void {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (isActive) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isActive]);
}
