import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Breakpoints } from './types';

const breakpointsPx: Breakpoints = {
  xs: 0,
  sm: 425,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1920,
};

function useIsMobile(): boolean {
  function getIsMobile(): boolean {
    if (typeof window === 'undefined') return false;
    return window.innerWidth < breakpointsPx.md;
  }

  const [mobile, setMobile] = useState(getIsMobile);

  function handleResize() {
    setMobile(getIsMobile());
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const throttledHandler = _.throttle(() => handleResize(), 100);
      window.addEventListener('resize', throttledHandler);
      return () => window.removeEventListener('resize', throttledHandler);
    }
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return mobile;
}

export default useIsMobile;
